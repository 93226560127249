import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
import productListConfig from 'modules/product/views/productListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardProductListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardProductListConfig = productListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardProductListConfig.search,
      filter: this.standardProductListConfig.filter,
      selectedData: this.standardProductListConfig.selectedData,
      sort: this.standardProductListConfig.sort,
      table: {
        data: <TableDataType>[
          {
            key: 'photos',
            label: 'data.photo',
            type: 'photo',
            photo: (row) => this.vm?.$helper.getProductMasterPhoto(row),
          },
          {
            key: 'name',
            label: 'data.name',
            type: 'text',
            component: () => import('modules/product/views/productList/product.vue'),
          },
          {
            key: 'category',
            label: 'data.category',
            type: 'text',
            component: () => import('modules/product/views/productList/categories.vue'),
          },
          {
            key: 'price',
            label: 'data.price',
            type: 'text',
            text: row => {
              if(row.price_max == row.price_min) {
                return this.vm?.$t('product.price.format', { price: row.price_max })
              }

              return this.vm?.$t('product.price_range.format', {
                max: row.price_max,
                min: row.price_min,
              })
            },
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
    }

  }
}

export default new listConfig()
